<template>
  <div>
    <div
      v-if="posts.length"
      class="client-dashboard"
      id="client-dashboard"
      data-app
    >
      <div class="client-dashboard__header">
        <div class="wrapper">
          <div class="cell">
            <div class="logo">
              <img class="logo__img" src="@/assets/img/logo_short.svg" />
            </div>

            <div class="title">
              Hi {{ smpostExportData.customerName }}! Please, rate new posts
            </div>
          </div>

          <div class="cell">
            <!--   <div class="info">
              <div class="item">
                <div class="item__title">Approved</div>
                <div class="item__data">7 <small>/7</small></div>
              </div>
              <div class="item time">
                <div class="item__title">Time left</div>
                <div class="item__data green">47h 59m</div>
              </div>
            </div> -->

            <button
              class="share-btn"
              v-if="posts.length"
              :disabled="!posts.some((v) => v.customerRating > 0)"
              @click="showModal"
            >
              Share feedback
            </button>
          </div>
        </div>

        <div class="mobile-title">
          Hi {{ smpostExportData.customerName }}! Please, rate new posts
        </div>
      </div>

      <Notification v-show="false" :message="notificationMessage" />

      <div class="client-dashboard__body">
        <PostList
          :posts="posts.filter((i) => i.type === 'post')"
          :stories="posts.filter((i) => i.type === 'story')"
          :postType="'client'"
          :currentCustomer="getCurrentCustomer"
          :isFacebookPlatform="isFacebookPlatform"
          :isShowSwitch="false"
          isShowRating
          isSortedByType
          isHeaderClient
          :isCanAddComment="isCanAddComment"
          isShowSubmitBtn
          isCanAddACoupon
          isShowEditIconForClient
          isShowIndication
          :focusToElementId="focusToElementId"
          :publishDateFormat="publishDateFormat"
          @focusOnInput="focusOnInputHandler"
          @blurFromInput="blurInputHandler"
          @updateRejectedList="setToRejectedList"
          @deleteFromRejectedContent="deleteFromRejectedContentHandler"
          @updatedCoupon="updatedCouponHandler"
          @changeDataInPost="changeData()"
          @changeDataInStory="changeData()"
          @updateCaptionValues="updateCaptionValuesHandler($event)"
        />
        <div class="share-btn-bottom-wrapper">
          <button
            class="share-btn-bottom"
            v-if="posts.length && $device.mobile"
            :disabled="!posts.some((v) => v.customerRating > 0)"
            @click="showModal"
          >
            Share feedback
          </button>
        </div>
      </div>
      <ModalDialog
        :isShow="dialog.isShow"
        :title="dialogHeader"
        :footerButtons="dialog.footerButtons"
        @success="updatePostHandler"
        @cancel="dialog.isShow = false"
        @close="dialog.isShow = false"
      >
        <template v-slot:description>{{ dialogText }}</template>
      </ModalDialog>

      <ModalDialog
        :customClass="'reject-modal-info'"
        :isShow="rejectedDialog.isShow"
        title="What made you reject this content?"
        subTitle="Choose all that applies"
        :footerButtons="rejectedDialog.footerButtons"
        @success="updatePostHandler"
        @close="closeRejectedDialogHandler()"
        @captions="clickOnModalButtonHandler"
        @media="clickOnModalButtonHandler"
        @post-topic="clickOnModalButtonHandler"
        :isCanClose="false"
        :isClickOutside="false"
      >
        <template v-slot:description v-if="rejectedContent.length">
          <div class="slider">
            <v-carousel
              v-if="rejectedContent[0].media.length"
              class="post__slider"
              height="200"
              :show-arrows="rejectedContent[0].media.length > 1"
              :hide-delimiters="rejectedContent[0].media.length <= 1"
              hide-delimiter-background
            >
              <v-carousel-item
                v-for="(item, index) in rejectedContent[0].media"
                :key="index"
              >
                <div class="content-container">
                  <img
                    v-if="item.type === 'image'"
                    class="post-image"
                    :src="
                      item.editedMediaUrlReduced
                        ? item.editedMediaUrlReduced
                        : item.mediaUrl
                    "
                  />

                  <VideoPlayer
                    v-else
                    :refName="'clientDashboardPostVideo' + index"
                    :videoSrc="item"
                  />
                </div>
              </v-carousel-item>
            </v-carousel>
          </div>

          <div class="review">
            <pre>{{ rejectedContent[0].text }}</pre>
          </div>
        </template>
        <template v-slot:footer_slot v-if="rejectedContent.length">
          <div class="modal__footer center_btn">
            <div class="btn-wrapper">
              <button
                class="btn border"
                :class="{
                  disabled: rejectedDialog.activeButtonsIds.length < 1,
                  active: rejectedDialog.activeButtonsIds.length >= 1,
                }"
                :disabled="rejectedDialog.activeButtonsIds.length < 1"
                @click="setRejectedVariant"
              >
                OK
              </button>
            </div>
          </div>
        </template>
      </ModalDialog>

      <ModalDialog
        :isShow="customCommentForRejected.isShow"
        title="Then please tell us what was wrong in your own words so we will learn how to improve"
        :footerButtons="customCommentForRejected.footerButtons"
        @success="updatePostHandler"
        :isCanClose="false"
        :isClickOutside="false"
      >
        <template v-slot:description>
          <v-textarea
            v-model="customCommentForRejected.customerComment"
            placeholder="Type your comment"
            outlined
          ></v-textarea>
        </template>
        <template v-slot:footer_slot>
          <div class="modal__footer center_btn">
            <div class="btn-wrapper">
              <button
                class="btn border"
                :class="{
                  disabled: !isCommentHasTwoWords,
                  active: isCommentHasTwoWords,
                }"
                :disabled="!isCommentHasTwoWords"
                @click="setCustomerComment"
              >
                OK
              </button>
            </div>
          </div>
        </template>
      </ModalDialog>
      <Referrals
        v-if="getCurrentCustomer && getCurrentCustomer.name != ''"
        id="referralContainer"
        :customerName="getCurrentCustomer.name"
      />

      <Loader v-if="isLoading" class="loader" />
    </div>

    <Loader v-else class="loader" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import PostList from "@/components/createPost/PostList";
import Notification from "@/components/global/Notification";
import Referrals from "@/components/global/Referrals";
import ModalDialog from "@/components/global/ModalDialog";
import Loader from "@/components/global/Loader";
import helper from "@/mixins/helper";
import VideoPlayer from "@/components/global/VideoPlayer";
import { log } from "logrocket";
//import LogRocket from "logrocket";

export default {
  name: "ClientDashboardPage",
  mixins: [helper],
  data: () => ({
    posts: [],
    smpostExportData: null,
    isLoading: false,
    dialog: {
      isShow: false,
      footerButtons: [
        { name: "Cancel", emitName: "cancel" },
        { name: "Yes", emitName: "success", btnClass: "violet" },
      ],
    },
    rejectedDialog: {
      isShow: false,
      footerButtons: [
        {
          name: "Captions",
          emitName: "captions",
          id: 1,
        },
        { name: "Media", emitName: "media", id: 2 },
        { name: "Post Topic", emitName: "post-topic", id: 3 },
      ],
      activeButtonsIds: [],
    },
    customCommentForRejected: {
      isShow: false,
      customerComment: "",
    },
    publishDateFormat: "MM/DD/YYYY",
    rejectedContent: [],
    counterId: null,
    count: null,
    isFocusOnCommentInput: false,
    focusToElementId: "",
    isBoosterCountDown: false,
    blockListForRejected: [],
    polling: null,
    isPromotion: false,
    id: null,
  }),
  computed: {
    dialogHeader() {
      return this.posts.some((v) => v.customerRating === 0)
        ? "Heads Up"
        : "You’re about to share your feedback";
    },
    dialogText() {
      return this.posts.some((v) => v.customerRating === 0)
        ? "Please note that you didn't give a score to all of your posts"
        : "Are you sure?";
    },
    isFacebookPlatform() {
      return (
        this.getCurrentCustomer.social_platforms.length === 1 &&
        this.getCurrentCustomer.social_platforms.includes("facebook")
      );
    },
    isCanAddComment() {
      if (!this.getCurrentCustomer.is_automated) return true;
      return this.getCurrentCustomer.is_automated === false;
    },
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    options() {
      return {
        type: "slide",
        perPage: 1,
        height: "30vh",
        arrows: true,
        autoWidth: false,
        pagination: true,
      };
    },
    isCommentHasTwoWords() {
      return (
        this.customCommentForRejected.customerComment
          .split(" ")
          .filter((i) => i.trim() !== "").length >= 2
      );
    },
    notificationMessage() {
      return `
          Please rate new posts within 48 hours. Non-rated posts will be
               ${
                 this.getCurrentCustomer.is_automated
                   ? "considered rejected"
                   : "automatically approved"
               }. <br />
               1 to 2 stars - post Rejected.<br />
               3 to 5 stars - post Approved.<br />
                ${
                  this.getCurrentCustomer.is_automated
                    ? ""
                    : "Optional - add a comment under relevant posts to help us improve!"
                }
          `;
    },
  },
  components: {
    PostList,
    Notification,
    ModalDialog,
    Referrals,
    Loader,
    VideoPlayer,
  },
  async created() {
    //LogRocket.track("CustomerLinkAccessed");

    if (this.$route.query.user_id) {
      await this.getPosts(this.$route.query.user_id);
    }

    if (this.$route.params.id) {
      await this.getPosts(this.$route.params.id);
    }

    /* LogRocket.identify(this.getCurrentCustomer.id, {
      name: this.smpostExportData.name,
    });*/

    this.pollData();

    this.delighted();
    window.delighted.survey({
      email: this.getCurrentCustomer.mail,
      name: this.getCurrentCustomer.name,
    });
  },
  methods: {
    ...mapActions("clientDashboard", [
      "getSmpostExport",
      "fetchPosts",
      "updatePosts",
      "updateStatus",
      "fetchCustomerInfo",
      "fetchPromotionData",
    ]),
    ...mapMutations("clientSelection", ["setCurrentCustomer"]),

    async changeData() {
      await this.pollData();
    },

    async getPosts(id) {
      this.id = id;
      this.smpostExportData = await this.getSmpostExport(id);
      await this.validateExportStatus();
      await this.setCustmerDetails();
      await this.handlePostsRetrival();
    },

    async validateExportStatus() {
      if (this.smpostExportData.status === "") {
        this.$router.push({ name: "404" });
      }
      if (this.smpostExportData.status === "done") {
        await this.getPromotion();
      }
      if (
        ["done", "expired"].includes(this.smpostExportData.status) &&
        !this.isPromotion
      ) {
        await this.$router.push({
          name: "client-status",
          params: {
            userName: this.smpostExportData.customerName,
            statusType: this.smpostExportData.status,
            id: this.$route.params.id,
            customerName: this.smpostExportData.name,
          },
        });
      }
    },

    async setCustmerDetails() {
      const clientInfo = await this.fetchCustomerInfo(
        this.smpostExportData.customerId
      );
      this.setCurrentCustomer(clientInfo);
    },

    async handlePostsRetrival() {
      if (!this.smpostExportData.postsIdList.length) return [];

      const postsObjectList = await this.fetchPosts(
        this.smpostExportData.postsIdList
      );
      if (!postsObjectList) {
        this.posts = [];
      }

      this.posts = postsObjectList.filter((post) => {
        return post.customerApprove === "none";
      });
    },
    showModal() {
      this.isBoosterCountdown = true;
      if (this.rejectedContent.length > 0 && this.$device.mobile) {
        this.countdownForFirstContentItem();
      } else {
        this.dialog.isShow = true;
      }
    },
    async updatePostHandler() {
      this.dialog.isShow = false;
      try {
        this.isLoading = true;
        await Promise.all(
          this.posts.map(async (post) => {
            if (post.customerRating || post.customerComment) {
              let params = {
                id: post.id,
                customerComment: post.customerComment,
                customerRating: post.customerRating,
              };

              if (post.contentCaptionIds.length) {
                params.contentCaptionId = post.contentCaptionId;
              }

              await this.updatePosts(params);
            }
          })
        );
        await this.getPromotion();
        if (
          this.posts.every((v) => v.customerRating !== 0 && !this.isPromotion)
        ) {
          await this.updatePostsStatus("done");
        } else if (!this.isPromotion) {
          await this.updatePostsStatus("pending");
          await this.getPosts(this.id);
        }
        //LogRocket.track("CustomerFeedbackSent");
        this.isLoading = false;
      } catch (e) {
        // LogRocket.track("CustomerFeedbackSentErr");
        throw e;
      }
    },

    async getPromotion() {
      try {
        const data = await this.fetchPromotionData(this.id);
        if (
          Object.keys(data).length &&
          JSON.parse(data).promotionItems.length
        ) {
          sessionStorage.setItem(
            "promotion",
            JSON.stringify({
              promotionItems: JSON.parse(data).promotionItems,
            })
          );
          this.isPromotion = true;
        } else {
          sessionStorage.setItem(
            "promotion",
            JSON.stringify({
              promotionItems: [],
            })
          );
          this.isPromotion = false;
        }

        if (this.smpostExportData.status === "done" && this.isPromotion) {
          this.$router.push({
            name: "client-status",
            params: {
              userName: this.smpostExportData.customerName,
              statusType: "promotion",
              customerName: this.smpostExportData.name,
              id: this.$route.params.id,
            },
          });
        }

        if (this.isPromotion && this.smpostExportData.status !== "done") {
          await this.updatePostsStatus("done");
          this.$router.push({
            name: "client-status",
            params: {
              userName: this.smpostExportData.customerName,
              userId: this.id,
              id: this.$route.params.id,
              statusType: "promotion",
              customerName: this.getCurrentCustomer.name,
            },
          });
        }
      } catch (e) {
        this.isPromotion = false;
        sessionStorage.setItem(
          "promotion",
          JSON.stringify({
            promotionItems: [],
          })
        );
        console.log(e);
      }
    },

    async updatePostsStatus(statusType = "pending") {
      try {
        const { status } = await this.updateStatus({
          id: this.id,
          status: statusType,
        });
        if (statusType === "done" && !this.isPromotion) {
          this.$router.push({
            name: "client-status",
            params: {
              id: this.$route.params.id,
              userName: this.smpostExportData.customerName,
              statusType: status,
              customerName: this.getCurrentCustomer.name,
            },
          });
        }
      } catch (e) {
        throw e;
      }
    },
    async delighted() {
      !(function (e, t, r, n) {
        if (!e[n]) {
          for (
            var a = (e[n] = []),
              i = [
                "survey",
                "reset",
                "config",
                "init",
                "set",
                "get",
                "event",
                "identify",
                "track",
                "page",
                "screen",
                "group",
                "alias",
              ],
              s = 0;
            s < i.length;
            s++
          ) {
            var c = i[s];
            a[c] =
              a[c] ||
              (function (e) {
                return function () {
                  var t = Array.prototype.slice.call(arguments);
                  a.push([e, t]);
                };
              })(c);
          }
          a.SNIPPET_VERSION = "1.0.1";
          var o = t.createElement("script");
          (o.type = "text/javascript"),
            (o.async = !0),
            (o.src =
              "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" +
              r +
              "/" +
              n +
              ".js");
          var p = t.getElementsByTagName("script")[0];
          p.parentNode.insertBefore(o, p);
        }
      })(window, document, "IRR1k8A8O6xLQRyZ", "delighted");
    },
    setToRejectedList(contentObject) {
      this.isBoosterCountdown = false;
      let findInState = this.rejectedContent.find(
        (element) => element.id === contentObject.id
      );

      if (findInState) {
        contentObject.countdown = findInState.countdown;
        this.rejectedContent = this.rejectedContent.filter(
          (val) => val.id !== contentObject.id
        );
      } else {
        contentObject.countdown = 3;
      }

      this.rejectedContent.push(contentObject);

      this.rejectedContent = this.rejectedContent.filter(
        (val) => val.customerComment.trim().length < 2
      );

      this.countdownForFirstContentItem();
    },
    deleteFromRejectedContentHandler(contentObject) {
      this.rejectedContent = this.rejectedContent.filter(
        (val) => val.id !== contentObject.id
      );
    },
    countdownForFirstContentItem() {
      if (this.counterId) {
        clearInterval(this.counterId);
      }
      this.isBoosterCountdown && this.rejectedContent.length
        ? (this.rejectedContent[0].countdown = 0)
        : null;
      this.count = this.rejectedContent.length
        ? this.rejectedContent[0].countdown
        : null;
      if (!this.isBoosterCountdown && !this.isFocusOnCommentInput) {
        this.counterId = setInterval(() => {
          if (
            this.count > 0 &&
            this.rejectedContent.length &&
            !this.isFocusOnCommentInput
          ) {
            this.count = this.count - 1;
            this.rejectedContent[0].countdown = this.count;
            this.rejectedDialog.isShow =
              !!this.rejectedContent.length && this.count === 0;
          }
        }, 1000);
      } else {
        if (this.rejectedContent.length) {
          this.rejectedContent.forEach((item) => {
            item.countdown = 0;
          });
          this.count = 0;
          this.rejectedDialog.isShow = true;
        }
      }
    },
    closeRejectedDialogHandler() {
      this.rejectedDialog.isShow = false;
      if (
        this.rejectedContent.length &&
        this.rejectedContent[0].customerComment.trim().length < 2
      ) {
        this.deleteFromRejectedContentHandler(this.rejectedContent[0]);
        this.countdownForFirstContentItem();
      }
    },
    clickOnQuickBtnHandler(text = "") {
      this.rejectedContent[0].customerComment = text;
      this.rejectedDialog.isShow = false;
      if (this.rejectedContent.length) {
        this.deleteFromRejectedContentHandler(this.rejectedContent[0]);
        this.countdownForFirstContentItem();
      }
    },
    focusOnInputHandler(post) {
      if (
        this.rejectedContent &&
        this.rejectedContent.length &&
        this.rejectedContent[0].id === post.id
      ) {
        this.isFocusOnCommentInput = true;
      }
    },
    blurInputHandler() {
      this.isFocusOnCommentInput = false;

      if (
        this.rejectedContent.length &&
        this.rejectedContent[0].customerComment.trim().length > 2
      ) {
        this.deleteFromRejectedContentHandler(this.rejectedContent[0]);
      }
      if (this.rejectedContent.length) {
        this.countdownForFirstContentItem();
      }
    },
    clickOnModalButtonHandler(buttonData) {
      if (this.rejectedDialog.activeButtonsIds.includes(buttonData.id)) {
        this.rejectedDialog.activeButtonsIds =
          this.rejectedDialog.activeButtonsIds.filter(
            (id) => id !== buttonData.id
          );
      } else {
        this.rejectedDialog.activeButtonsIds.push(buttonData.id);
      }

      this.setUnsetActiveClassToButtons();
    },
    setUnsetActiveClassToButtons() {
      this.rejectedDialog.footerButtons.map((button) => {
        if (this.rejectedDialog.activeButtonsIds.includes(button.id)) {
          button.name = button.name + " ";
          button.btnClass = "active border";
        } else {
          button.name = button.name + " ";
          button.btnClass = "border";
        }
      });
    },
    setRejectedVariant() {
      if (this.rejectedDialog.activeButtonsIds.length < 3) {
        let comment = "";
        this.rejectedDialog.activeButtonsIds.forEach((id) => {
          comment +=
            "Rejected due to " +
            this.rejectedDialog.footerButtons
              .find((button) => button.id === id)
              .name.trim() +
            ". ";
        });
        if (comment.trim() !== "") {
          this.clickOnQuickBtnHandler(comment);
          this.refreshAllRejectedData();
        }
      } else {
        this.rejectedDialog.isShow = false;
        this.customCommentForRejected.isShow = true;
      }
    },
    setCustomerComment() {
      this.clickOnQuickBtnHandler(
        this.customCommentForRejected.customerComment
      );
      // this.customCommentForRejected.isShow = true;
      this.refreshAllRejectedData();
    },
    refreshAllRejectedData() {
      this.rejectedDialog = {
        isShow: false,
        footerButtons: [
          {
            name: "Captions",
            emitName: "captions",
            id: 1,
          },
          { name: "Media", emitName: "media", id: 2 },
          { name: "Post Topic", emitName: "post-topic", id: 3 },
        ],
        activeButtonsIds: [],
      };
      this.customCommentForRejected = {
        isShow: false,
        customerComment: "",
      };
    },
    async pollData() {
      clearTimeout(this.polling);
      this.polling = setTimeout(async () => {
        this.posts.map(async (post) => {
          await this.updatePosts({
            id: post.id,
            customerComment: post.customerComment,
            customerRating: post.customerRating,
          });
        });
      }, 2000);
    },
    updatedCouponHandler(postOrStory) {
      const index = this.posts.findIndex((item) => item.id === postOrStory.id);

      this.posts.splice(index, 1, postOrStory);
    },
    updateCaptionValuesHandler(params) {
      const foundIndex = this.posts.findIndex(
        (post) => post.id === params.postId
      );

      this.posts.splice(foundIndex, 1, {
        ...this.posts[foundIndex],
        ...params.newValues,
      });
    },
  },
  beforeDestroy() {
    clearTimeout(this.polling);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.client-dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 72px;

  .share-btn-bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .share-btn-bottom {
    height: 36px;
    width: 90%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 75px auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $color-white;
    border-radius: 18px;
    cursor: pointer;
    background: $color-smb-black;
    transition: all 0.3s ease;

    &:hover {
      background: darken($color-smb-black, 10);
    }

    &:disabled {
      background: #eeeff0;
      color: $color-grey2;
      cursor: default;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 18px 20px;
    background: $color-white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .mobile-title {
      display: none;
    }

    .logo {
      width: 51px;
      height: 30px;
      flex-shrink: 0;
      margin-right: 25px;

      &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .info {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;
      @media screen and (max-width: 920px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .item {
        display: inline-flex;
        align-items: baseline;
        margin-left: 41px;
        position: relative;
        @media screen and (max-width: 920px) {
          margin-left: 0;
        }

        &:before {
          content: "";
          position: absolute;
          width: 1px;
          height: 22px;
          background: rgba(47, 72, 88, 0.1);
          left: -21px;
          top: 0;
          @media screen and (max-width: 920px) {
            display: none;
          }
        }

        &:first-child {
          margin-left: 0;

          &:before {
            display: none;
          }
        }

        &.time {
          @media screen and (max-width: 920px) {
            .item {
              &__title {
                font-size: 8px !important;
                line-height: 140% !important;
              }

              &__data {
                font-size: 8px !important;
                line-height: 140% !important;
              }
            }
          }
        }

        &__title {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: #515456;
          margin-right: 8px;
        }

        &__data {
          color: $color-orange;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;

          &.green {
            color: $color-green;
          }

          &.red {
            color: $color-red;
          }
        }
      }
    }

    .share-btn {
      height: 36px;
      padding: 0 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $color-white;
      border-radius: 18px;
      cursor: pointer;
      background: $color-smb-black;
      transition: all 0.3s ease;

      &:hover {
        background: darken($color-smb-black, 10);
      }

      &:disabled {
        background: #eeeff0;
        color: $color-grey2;
        cursor: default;
      }
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      color: $color-black;
      @media screen and (max-width: 920px) {
        font-size: 18px;
      }
      @media screen and (max-width: 740px) {
        display: none;
      }
    }

    .cell {
      display: flex;
      align-items: center;

      &:last-child {
        flex-shrink: 0;
      }
    }
  }

  &__body {
    background: #f3f2f3;
    flex: 1;
  }

  .loader {
    ::v-deep {
      position: fixed;
    }
  }

  ::v-deep {
    .post-list {
      width: 100%;
      &__item {
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          flex: 1;
        }
      }
    }
  }
}
</style>
